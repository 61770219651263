/**
 * Confluence:
 * https://unifonic.atlassian.net/wiki/spaces/UEP/pages/2573533195/UniShield+General+Fraud+Dashboard+GeoPermissions+and+Content+Moderator
 *
 * Available queries:
 *      1. getUniShieldFraudData
 */

import { gql } from "apollo-angular";
export const DEFAULT_OUTPUT = `
    blockedMessagesBySender
    geoPermissionEstimatedCostSavingUSD
    geoPermissionTotalBlockedMessages
    overviewEstimatedCostSavingUSD
    overviewTotalBlockedMessages
    gpBlockedTrafficBySenderLogs{
        senderName
        count
    }
`

// 1. getUniShieldFraudData
export const getUniShieldFraudData = (output: string) => {
    return gql`
        query GetUniShieldFraudData(
          $startDate: String!,
          $endDate: String!,
          $accountId : String!,
          $senderName: String
        ){
          getUniShieldFraudData(
            startDate: $startDate,
            endDate: $endDate,
            accountId: $accountId,
            senderName: $senderName
          ){
            success, message, count, errorCode, entries {
              ${output}
            }
          }
        }
    `
}
