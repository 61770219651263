import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from "rxjs";
import { map, tap, withLatestFrom } from "rxjs/operators";
import { UnishieldFetchStore } from 'src/app/shared/data-access/unishield/unishield.store';
import { mapDatePickerValuesToDateRanges } from "src/app/shared/utils/map-date-picker-values-to-date-ranges";
import { FiltersStore } from "./filters.store";
import { UniAuthFacade, momentTimezone } from "unifonic-spa-common/src";


type State = {}

const initialState: State = {}

@Injectable()
export class SecurityFetchStore extends ComponentStore<State>{
    constructor(
        private filters: FiltersStore,
        private unishieldFetchStore: UnishieldFetchStore,
        private authFacade: UniAuthFacade

    ){
        super(initialState)
    }

    readonly timezone$ = this.authFacade.userMe$.pipe(
      map((user: any) => user.user.timezone)
    )

    readonly accountId$ = this.authFacade.userMe$.pipe(
      map((user: any) => user.user.account.id)
    )


    // effect
    readonly fetchData = this.effect((trigger$: Observable<void>) => {
        return this.filters.params$.pipe(
          withLatestFrom(
            this.timezone$,
            this.accountId$
          ),
          tap(([params, timezone, accountId]) => {
            return this.unishieldFetchStore.fetchData({
                variables:  {
                    timezone: timezone,
                    accountId: accountId,
                    ...mapDatePickerValuesToDateRanges(params.date || '', params.timezone),
                }
            })
          })
        )
    })
}
