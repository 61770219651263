
import { rest } from 'msw'

const faker = {
  datatype: { uuid: () => ''},
  name: {fullName: () => ''}
}

const accounts = {
  generate(number) {
    return [{
      id: '096ec513-f82e-11e8-809b-0252151e4411"',
      name: 'Test Account'
    }, {
      id: '096db07c-f82e-11e8-809b-0252151e4411',
      name: 'Traffic Logs Account'
    }, {
      id: 'de6fb171-a55d-45e8-8be6-7df00865bacc',
      name: 'Whatsapp Message Logs'
    },{
      id: '5ec72154-bdaa-4782-b017-96110ad4b1f1',
      name: 'Kevin Unifon (logged)'
    },{
      id: 'ae0474f8-edf7-427b-add4-30b0d4edeb31',
      name: 'Campaigns Available'
    },
    ...Array(number).fill().map((_, i) => ({
      id: i,
      name: "Element " + i

    }))]
  }
}

export const getAccountsList = rest.get('http://0.0.0.0/api/v2/accounts/list', async (req, res, ctx) => {
  console.log('%c MSW query param string:' + req.url.search, 'font-weight: bold')
  const numberOfResults = Math.round(Math.random() * (40 - 20) + 20)
  // const numberOfResults = 0
  const data = accounts.generate(numberOfResults)
  await new Promise((resolve) => setTimeout(() => resolve(), 2000))
  return res(
    ctx.json(data)
  )
})