<ng-container *ngIf="vm$ | async as vm">
  <uni-box>
    <uni-box-content [isVerticalSpace]="false">
      <header>
        <app-span>{{'ci.unishield.blockedWords' | translate}}</app-span>
      </header>
      <app-blocked-cards></app-blocked-cards>
    </uni-box-content>
  </uni-box>
</ng-container>
