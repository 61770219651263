import { Observable, of } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';


export class CustomUrlInterceptor implements HttpInterceptor{
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const origin = location.origin

    const zainBrowserUrlOrigin = environment.zainBrowserUrlOrigin
    const mainDomain = environment.mainRequestHost
    const zainDomain = environment.zainRequestHost
  
    console.log(origin, zainBrowserUrlOrigin, mainDomain, zainDomain)
    
    if(origin === zainBrowserUrlOrigin && req.url.includes(mainDomain)){
      const newUrl = req.url.replace(mainDomain, zainDomain)
      return next.handle(req.clone({url: newUrl}))
    }




    return next.handle(req.clone())
  }
}