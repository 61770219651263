import { Injectable } from "@angular/core"
import { ComponentStore } from "@ngrx/component-store"

import { UnishieldFetchStore } from "src/app/shared/data-access/unishield/unishield.store";


type State = {
}

const initialState: State = {
}

@Injectable()
export class GeopermissionsStore extends ComponentStore<State>{

    constructor(
      private unishieldFetchStore: UnishieldFetchStore
    ){
      super(initialState)
    }

    //selectors
    readonly loading$ = this.unishieldFetchStore.loading$

    readonly vm$ = this.select(
      this.unishieldFetchStore.entities$,
      this.loading$,
      (entity, loading) => {
        return {
          entity,
          loading
        }
      }
    )
}
