<ng-container *ngIf="vm$ | async as vm">
  <uni-box [isMargin]="'top'">
    <app-loading-mask *ngIf="vm.loading"></app-loading-mask>
    <uni-box-content [isVerticalSpace]="false">
      <header>
        <app-span>{{'ci.unishield.overview' | translate}}</app-span>
      </header>
      <app-blocked-cards
        [blocked]="vm.entity.overviewTotalBlockedMessages"
        [savings]="vm.entity.overviewEstimatedCostSavingUSD"
      ></app-blocked-cards>
    </uni-box-content>
  </uni-box>
</ng-container>
