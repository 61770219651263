import { Injectable } from "@angular/core"
import { ComponentStore } from "@ngrx/component-store"
import { DateRangeMockStore } from "src/app/shared/store-mocks/date-range.store";
import { UniAuthFacade } from "@unifonic/common";
import { map } from "rxjs/operators";
import { UnishieldFetchStore } from "src/app/shared/data-access/unishield/unishield.store";

type State = {
}

const initialState: State = {
}

@Injectable()
export class OverviewStore extends ComponentStore<State>{
  constructor(
    private unishieldFetchStore: UnishieldFetchStore
  ){
      super(initialState)
  }

  //selectors
  readonly loading$ = this.unishieldFetchStore.loading$

  readonly vm$ = this.select(
    this.unishieldFetchStore.entities$,
    this.loading$,
    (entity, loading) => {
      return {
        entity,
        loading
      }
    }
  )
}
