import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { map, skip, tap } from "rxjs/operators";
import { DateRangeMockStore } from "src/app/shared/store-mocks/date-range.store";
import { UniAuthFacade, momentTimezone } from "unifonic-spa-common/src";


type State = {
  date: string
}

const initialState: State = {
  date: 'last7d'
}

@Injectable()
export class FiltersStore extends ComponentStore<State>{
    constructor(
        private authFacade: UniAuthFacade
    ){
        super(initialState)
    }


    readonly date$ = this.select(state => state.date)

    //selectors
    readonly params$ = this.select(
        this.date$,
        (date) => ({
            date,
            timezone: this.authFacade.userMe?.user?.timezone,
            accountId: this.authFacade.userMe?.user?.account?.id
        })
    )

}

