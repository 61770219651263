<table>
  <thead>
    <tr>
      <th [style.width.%]="80">
        <div class="flex justify-content-start">
          {{'ci.unishield.topCountries' | translate | uppercase}}
        </div>
      </th>
      <th [style.width.%]="20">
        <div class="flex justify-content-end">
          {{'ci.unishield.messagesBlocked' | translate | uppercase}}
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container  *ngFor="let item of data?.campaignData">
      <tr>
        <td>
          {{item.countryName}}
        </td>
        <td>
          {{item.count}}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
