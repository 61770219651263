<ng-container *ngIf="vm$ | async as vm">
  <uni-box [isMargin]="'top'">
    <app-loading-mask *ngIf="vm.loading"></app-loading-mask>
    <uni-box-content [isVerticalSpace]="false">
      <header>
        <app-span>{{'ci.unishield.geopermissions' | translate}}</app-span>
      </header>
      <app-blocked-cards
        [blocked]="vm.entity.geoPermissionTotalBlockedMessages"
        [savings]="vm.entity.geoPermissionEstimatedCostSavingUSD"
      ></app-blocked-cards>
      <app-blocked-by-sender

      ></app-blocked-by-sender>
      <!-- <app-countries-table></app-countries-table> -->
    </uni-box-content>
  </uni-box>
</ng-container>
